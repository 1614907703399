<template>
  <b-card
    class="h-100 w-100"
    :sub-title="matchTitle"
    body-class="d-flex flex-column"
  >
    <b-row class="flex-nowrap">
      <b-col
        sm="5"
        class="text-center"
      >
        <b-avatar
          :src="match?.opponent1?.participant?.avatar_image"
          :variant="`light-primary`"
          class="mb-2"
          size="50px"
        />
      </b-col>

      <b-col class="d-flex align-items-center justify-content-center">
        <p class="font-weight-bold">
          VS
        </p>
      </b-col>

      <b-col
        sm="5"
        class="text-center"
      >
        <b-avatar
          :src="match?.opponent2?.participant?.avatar_image"
          :variant="`light-primary`"
          class="mb-2"
          size="50px"
        />
      </b-col>
    </b-row>

    <b-row class="flex-nowrap">
      <b-col
        sm="5"
        class="text-center"
      >
        <h4 class="mb-2 text-truncate">
          {{
            match?.opponent1?.participant
                ? match?.opponent1?.participant.name
                : match.opponent1?.name
          }}
        </h4>
      </b-col>

      <b-col />

      <b-col
        sm="5"
        class="text-center"
      >
        <h4 class="mb-2 text-truncate">
          {{
            match?.opponent2?.participant
                ? match?.opponent2?.participant.name
                : match?.opponent2?.name
          }}
        </h4>
      </b-col>
    </b-row>

    <b-row
      v-if="match?.status_id === MatchStatusType.COMPLETE"
      class="flex-nowrap"
    >
      <b-col
        class="text-center"
        sm="5"
      >
        <div><strong>{{ match?.opponent1_score }}</strong></div>
      </b-col>

      <b-col />

      <b-col
        class="text-center"
        sm="5"
      >
        <div>
          <strong>
            {{ match?.opponent2_score }}
          </strong>
        </div>
      </b-col>
    </b-row>

    <b-row
      v-if="match?.status_id === MatchStatusType.COMPLETE || match?.status_id === MatchStatusType.DISPUTED"
      class="
      flex-nowrap"
    >
      <b-col
        class="text-center"
        sm="5"
      >
        <b-badge
          pill
          :variant="resolveMatchResultBadgeColor(match?.result?.opponent1_result)"
        >
          {{ resolveMatchResultBadgeValue(match?.result?.opponent1_result) }}
        </b-badge>
      </b-col>

      <b-col />

      <b-col
        class="text-center"
        sm="5"
      >
        <b-badge
          pill
          :variant="resolveMatchResultBadgeColor(match?.result?.opponent2_result)"
        >
          {{ resolveMatchResultBadgeValue(match?.result?.opponent2_result) }}
        </b-badge>
      </b-col>
    </b-row>

    <!-- Match status -->
    <b-row class="mb-1 flex-nowrap">
      <b-col class="text-center">
        <b-badge
          v-if="match?.status_id === MatchStatusType.READY || match?.status_id === MatchStatusType.WAITING || match?.status_id === MatchStatusType.LOCKED"
          class="ml-1"
          pill
          variant="light-secondary"
        > In progress
        </b-badge>

        <b-badge
          v-if="match?.status_id === MatchStatusType.RUNNING"
          class="ml-1"
          pill
          variant="light-success"
        > In progress
        </b-badge>

        <b-badge
          v-if="match?.status_id === MatchStatusType.COMPLETE"
          class="ml-1 p-1"
          pill
          variant="light-success"
        > COMPLETE
        </b-badge>

        <b-badge
          v-if="match?.status_id === MatchStatusType.DISPUTED"
          class="ml-1 p-1"
          pill
          variant="light-danger"
        > DISPUTED
        </b-badge>
      </b-col>
    </b-row>

    <!-- Match info redirect  -->
    <b-row class="mt-auto">
      <b-col class="text-center">
        <b-button
          size="sm"
          variant="primary"
          @click="handleMatchEditClick"
        >
          Edit match
        </b-button>
      </b-col>

    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCol, BRow, BButton, BBadge, BAvatar,
} from 'bootstrap-vue'
import MatchStatusType from '@/constants/MatchStatusType'

export default {
  components: {
    BBadge,
    BCard,
    BCol,
    BRow,
    BButton,
    BAvatar,
  },
  props: {
    match: {
      type: Object,
      default: null,
    },
    matchViewCallback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      MatchStatusType,
    }
  },
  computed: {
    matchTitle() {
      if (this.match?.tournament?.settings?.first_stage_structure === 2) {
        return `${this.match?.group?.index === 1 ? 'WB' : 'LB'} ${this.match?.group.index} ${this.match?.round?.index}.${this.match?.index}`
      }

      return `M ${this.match?.group.index} ${this.match?.round?.index}.${this.match?.index}`
    },
  },
  methods: {
    resolveMatchResultBadgeValue(result) {
      switch (result) {
        case 'W':
          return 'WON'
        case 'L':
          return 'LOST'
        case 'D':
          return 'DRAW'
        default:
          return '  ?  '
      }
    },

    resolveMatchResultBadgeColor(result) {
      if (result === 'W') {
        return 'success'
      }

      if (result === 'L') {
        return 'danger'
      }

      return 'secondary'
    },
    handleMatchEditClick() {
      this.$emit('onOrganizerMatchEditClick', this.match)
    },
  },
}
</script>

<style lang="scss">

</style>

import { render, staticRenderFns } from "./OrganizerTournamentMatches.vue?vue&type=template&id=280ace94&scoped=true"
import script from "./OrganizerTournamentMatches.vue?vue&type=script&lang=js"
export * from "./OrganizerTournamentMatches.vue?vue&type=script&lang=js"
import style0 from "./OrganizerTournamentMatches.vue?vue&type=style&index=0&id=280ace94&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "280ace94",
  null
  
)

export default component.exports
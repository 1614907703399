<template>
  <div
    style="height: inherit"
  >
    <organizer-match-modal :match="selectedMatch" />

    <!-- View header -->
    <b-card
      no-body
      class="p-2"
    >
      <b-row class="align-items-center justify-content-between">
        <b-col
          sm="12"
          md="6"
        >
          <h5 class="m-0">
            All matches
          </h5>
        </b-col>
        <b-col
          sm="12"
          md="6"
          class="d-flex justify-content-end"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="searchQuery"
              placeholder="Search participant ..."
              type="search"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                height="20px"
                icon="SearchIcon"
                width="20px"
              />
            </b-input-group-append>\
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- /View header -->

    <b-row
      v-if="loading"
      class="text-center p-2"
    >
      <b-spinner label="Loading..." />
    </b-row>

    <b-row v-if="tournament?.status_id < TournamentStatusType.PENDING">
      <b-col
        cols="5"
        class="d-flex flex-column align-items-center"
      >
        Available when tournament is started
      </b-col>
    </b-row>

    <b-row v-if="!loading && tournament.status_id >= TournamentStatusType.PENDING">
      <!-- Matches list filter -->
      <b-col class="d-flex justify-content-center justify-content-md-start">
        <b-form-group>
          <b-form-radio-group
            id="btn-radios-1"
            v-model="filter"
            button-variant="outline-primary"
            :options="filterOptions"
            buttons
            name="radios-btn-default"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <vue-perfect-scrollbar
      v-if="!loading && tournament.status_id >= TournamentStatusType.PENDING"
      class="scroll-area"
      :settings="perfectScrollbarSettings"
    >
      <b-row>
        <!-- Matches list -->
        <b-col
          v-for="match in matches"
          :key="match.id"
          class="d-flex mb-1"
          md="6"
          sm="12"
          lg="3"
        >
          <organizer-tournament-match-card
            :key="match.id"
            :match="match"
            @onOrganizerMatchEditClick="handleMatchEditClick"
          />
        </b-col>

        <!-- No matches  -->
        <b-col
          v-if="!loading && (!matches || matches?.length === 0)"
          sm="12"
        >
          <div class="d-flex justify-content-center flex-column">
            <div class="font-italic m-2 d-flex justify-content-center">
              <feather-icon
                icon="InfoIcon"
                size="40"
              />
            </div>
            <div class="d-flex flex-column align-items-center ">
              <p>No matches found.</p>
              <p
                v-if="tournament?.status_id === TournamentStatusType.PUBLISHED"
                class="font-italic "
              >
                Matches are going to appear once tournament starts
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>

  </div>
</template>
<script>
import {
  BCard, BCol, BFormGroup, BFormInput, BFormRadioGroup, BInputGroup, BInputGroupAppend, BRow, BSpinner,
} from 'bootstrap-vue'
import MatchStatusType from '@/constants/MatchStatusType'
import { myTeamsAreParticipatingInTournament } from '@/views/tournament/tournament-utils'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import TournamentStatusType from '@/constants/TournamentStatusType'
import OrganizerTournamentMatchCard from '@/views/dashboard/tournament/OrganizerTournamentMatchCard.vue'
import OrganizerMatchModal from '@/views/dashboard/OrganizerMatchModal.vue'

export default {
  components: {
    OrganizerMatchModal,
    OrganizerTournamentMatchCard,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormGroup,
    VuePerfectScrollbar,
  },
  data() {
    return {
      MatchStatusType,
      selectedMatchId: null,
      selectedMatch: null,
      matches: [],
      matchesAll: [],
      loading: false,
      myMatches: [],
      searchQuery: '',
      filter: 'all',
      perfectScrollbarSettings: {
        suppressScrollX: true,
      },
      filterOptions: [
        {
          text: 'All',
          value: 'all',
        },
        {
          text: 'Upcoming',
          value: 'upcoming',
        },
        {
          text: 'Disputed',
          value: 'disputed',
        },
        {
          text: 'Finished',
          value: 'finished',
        },
      ],
    }
  },
  computed: {
    TournamentStatusType() {
      return TournamentStatusType
    },
    tournament() {
      return this.$store.getters['tournament/getTournament'] || { status_id: null }
    },
    isLoading() {
      return this.$store.getters['tournament/isLoading']
    },
  },
  watch: {
    async searchQuery(searchValue) {
      if (!searchValue) {
        this.matches = this.matchesAll

        return
      }

      this.matches = this.matches?.filter(
        match => match.opponent1?.participant?.name?.toLowerCase().includes(searchValue.toLowerCase())
              || match.opponent1?.name?.toLowerCase().includes(searchValue.toLowerCase())
              || match.opponent2?.participant?.name?.toLowerCase().includes(searchValue.toLowerCase())
              || match.opponent2?.name?.toLowerCase().includes(searchValue.toLowerCase()),
      )
    },
    async filter(value) {
      if (!value || value === 'all') {
        this.matches = this.matchesAll
      } else {
        if (this.filter === 'upcoming') {
          this.matches = this.matchesAll.filter(match => match.status_id === MatchStatusType.READY)
        }

        if (this.filter === 'finished') {
          this.matches = this.matchesAll.filter(match => match.status_id === MatchStatusType.COMPLETE)
        }

        if (this.filter === 'disputed') {
          this.matches = this.matchesAll.filter(match => match.status_id === MatchStatusType.DISPUTED)
        }
      }
    },
  },
  async created() {
    this.matchesAll = await this.fetchMatches()

    this.matches = this.matchesAll
  },
  methods: {
    participatingInTournament: myTeamsAreParticipatingInTournament,
    handleMatchEditClick(match) {
      this.selectedMatch = JSON.parse(JSON.stringify(match))

      this.$bvModal.show('organizer-match-modal')
    },
    async fetchMatches() {
      this.loading = true

      const { data } = await this.$api.tournament.fetchTournamentMatches(this.$route.params.tournamentId)

      this.loading = false

      return data
    },
  },
}
</script>

<style lang="scss" scoped>
.scroll-area {
  height: calc(100% - 155px);
  padding-right: 1rem;
}
</style>
